var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2dL1vXIb2Rxvg-cCR0pXc"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

import { production as prodConfig } from './src/config/production'
import { stage as stageConfig } from './src/config/stage'

const config = process.env.NODE_ENV === 'production' ? prodConfig : stageConfig

Sentry.init({
  dsn: config.sentry.dsn,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.2,
  sampleRate: 0.25,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  tracePropagationTargets: [config.api.endpoint]
})
